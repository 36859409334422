//import CreateContactForm from "./CreateContactForm";

//const ContactForm = new CreateContactForm({
    //selectors: {
        //responseMethod: '.sq-form-field[name="q1904:q2"]',
        //email: '.contact-form__email',
        //phone: '.contact-form__phone',
        //address: '.contact-form__address',
        //form: '#form_email_1898'
    //},
    //classes: {
        //active: 'active'
    //}
//});

//window.addEventListener('load', () => {
    //ContactForm.init();
//});
