import CreateShareThis from "./CreateShareThis";

const ShareThis = new CreateShareThis({
    selectors: {
        shareLink: '.page-utilities__share',
        title: '.banner__heading',
        text: '.banner__subheading'
    }
});

window.addEventListener('load', () => {
    ShareThis.init();
})