/**
 * Create the dropdown menu, complete with accessibility.
 * 
 * @param {object} options 
 * 
 * @returns {object}
 */
const CreateDropdownMenu = (options) => {
  let dropdownLinks = [];
  const itemsWithDropdown = Array.from(document.querySelectorAll(options.selectors.itemsWithDropdown));
  const topLevelLinks = Array.from(document.querySelectorAll(options.selectors.topLevelLinks));
  const previousLogo = document.querySelector(options.selectors.previousLogo);

  /**
   * Retrieves the links we will use to activate the dropdowns using
   * keyboard navigation.
   * 
   * @returns {boolean}
   */
  const __getDropdownLinks = () => {
    if (itemsWithDropdown.length > 0) {
      itemsWithDropdown.forEach(item => {
        const link = item.querySelector(options.selectors.link);

        if (link instanceof HTMLElement) {
          dropdownLinks.push(link);
        }
      });
    }

    return dropdownLinks.length > 0;
  };

  /**
   * Watch for certain keydown events.
   * 
   * @param {object} e 
   * @param {object} link 
   * 
   * @returns {void}
   */
  const __toggleDropdown = (e, link) => {
    const dropdown = link.nextElementSibling;

    // If event is keydown and we have a valid dropdown, toggle the menu.
    if ((e.key === 'ArrowDown' || e.code === 'Space' || e.code === 'Enter') &&
      dropdown instanceof HTMLElement &&
      dropdown.classList.contains(options.classes.dropdown)
    ) {
        switch (dropdown.parentNode.classList.contains(options.classes.active)) {
          case true:
            dropdown.parentNode.classList.remove(options.classes.active);
            break;
          case false:
            dropdown.parentNode.classList.add(options.classes.active);
            break;
        }
    } else if (e.key === 'ArrowUp') {
      dropdown.parentNode.classList.remove(options.classes.active);
    }
  };

  /**
   * Loops through the given dropdown links and adds the keydown event listener.
   * 
   * @returns {void}
   */
  const __setupKeyboardNav = () => {
    window.addEventListener('keyup', e => __closeActiveDropdowns(e, 'Escape'));

    // Add event listener to top level links that have a dropdown.
    dropdownLinks.forEach(link => {
      link.addEventListener('keyup', e => __toggleDropdown(e, link));
    });
    
    // Go through the top level links, if a dropdown is active when we hover/focusin
    // on another top level link, turn off the dropdown.
    if (topLevelLinks.length > 0) {
      topLevelLinks.forEach(link => {
        if (link instanceof HTMLElement) {
          // link.addEventListener('mouseover', e => __closeActiveDropdowns(e, 'hover'));

          if (!link.classList.contains(options.classes.itemsWithDropdown)) {
            link.addEventListener('focusin', e => __closeActiveDropdowns(e, 'focus'));
          }
        }
      });
    }
    
    // If the user focuses on the previous link (i.e. the link that houses the logo),
    // we want to hide all active dropdowns.
    if (previousLogo instanceof HTMLElement) {
      previousLogo.parentNode.addEventListener('focusin', e => __closeActiveDropdowns(e, 'focus'));
    }
  };

  /**
   * Closes the active dropdown by removing the active class and setting the tabindex
   * child links to -1.
   * 
   * @param {object}  e
   * @param {string}  expectedKey
   * 
   * @returns {void}
   */
  const __closeActiveDropdowns = (e, expectedKey) => {
    const activeDropdownsSelector = `${options.selectors.itemsWithDropdown}.${options.classes.active}`;
    const activeDropdowns = Array.from(document.querySelectorAll(activeDropdownsSelector));

    if ((e.key === expectedKey || expectedKey === 'hover' || expectedKey === 'focus') &&
      activeDropdowns.length > 0
    ) {
      activeDropdowns.forEach(dropdown => {
        if (dropdown instanceof HTMLElement) {
          dropdown.classList.remove(options.classes.active);
        }
      });
    }
  };

  /**
   * Init the component. This is the only function that's publicly exposed.
   * 
   * @return {void}
   */
  const init = () => {
    const dropdownsExist = __getDropdownLinks();

    // If we have links, setup the necessary keyboard events to watch for.
    if (dropdownsExist) {
      __setupKeyboardNav();
    }
  };

  /**
   * Return the publicly exposed init function.
   */
  return Object.freeze({
    init,
  });
}

export default CreateDropdownMenu;