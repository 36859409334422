import CreateKMSAccordions from "./CreateKMSAccordions";

const KMSAccordions = new CreateKMSAccordions({
    selectors: {
        nestedAccordionButtons: 'p button.stAccordion',
        accordionButtons: '.kms-accordion-button',
        accordionDropdowns: '.kms-accordion-dropdown',
    },
    classes: {
        accordionButton: 'kms-accordion-button',
        accordionDropdown: 'kms-accordion-dropdown',
        active: 'active'
    }
});

window.addEventListener('load', () => {
    KMSAccordions.init();
});