import CreateKeyInfoCta from './CreateKeyInfoCta';

const KeyInfoCta = new CreateKeyInfoCta({
    selectors: {
        actionStep: '#actionStep',
        linkText: {
            el: 'td',
            pos: 0
        },
        link: 'a',
        summary: {
            el: 'td',
            pos: 2
        },
        img: 'img'
    },
    classes: {
        show: 'd-block'
    }
});

// On page load, init the component.
window.addEventListener('load', () => {
    KeyInfoCta.init();
});