import { exists, toRad } from "../../_global/js/utils";

/**
 * For finding the nearest store.
 * 
 * @param {object} options 
 * 
 * @returns {object}
 */
const CreateFindNearestStore = (options) => {
    const earthRadius = 6371;
    const component = document.querySelector(options.selectors.component);
    const town = document.querySelector(options.selectors.town);
    const address = document.querySelector(options.selectors.address);
    const open = document.querySelector(options.selectors.open);
    const phone = document.querySelector(options.selectors.phone);
    const link = document.querySelector(options.selectors.link);
    const description = document.querySelector(options.selectors.description);
    const geolocationConfig = {
        enableHighAccuracy: true
    };
    
    /**
     * Delete the elements from DOM that are empty or have no data
     * to keep things clean.
     * 
     * @param {array} hiddenItems 
     * 
     * @returns {void}
     */
    const __purgeHiddenItems = hiddenItems => {
        hiddenItems.forEach(el => {
            el.parentNode.removeChild(el);
        });
    };

    /**
     * Take the targeted HTML from earlier, and apply the data we have stored
     * to display the nearest store to the user.
     * 
     * @param {object} data 
     * 
     * @returns {void}
     */
    const __showNearestStore = (data) => {
        let hiddenItems = [];

        if (data.town.length > 0) {
            town.innerText = data.town;
        } else {
            hiddenItems.push(town);
        }

        if (data.address.length > 0) {
            address.innerText = data.address;
        } else {
            hiddenItems.push(address);
        }

        if (data.open.length > 0) {
            open.innerText = data.open;
        } else {
            hiddenItems.push(open);
        }
        
        if (data.phone.length > 0) {
            phone.innerHTML = `Phone: <a href="tel:${data.phone.replace(/\s/g, '')}">${data.phone}</a>`;
        } else {
            hiddenItems.push(phone);
        }

        if (data.description.length > 0) {
            description.innerHTML = data.description;
        } else {
            hiddenItems.push(description);
        }
        
        if (data.link.length > 0) {
            link.href = data.link;
        } else {
            hiddenItems.push(link);
        }
        
        if (exists(hiddenItems)) {
            __purgeHiddenItems(hiddenItems);
        }

        component.classList.add(options.classes.active);
    };

    /**
     * Find the nearest store using the Haversine Formula in tandem with the 
     * users current geolocation.
     * 
     * Read: https://stackoverflow.com/questions/14560999/using-the-haversine-formula-in-javascript
     * 
     * @param {object} position 
     * 
     * @returns {void}
     */
    const __findNearestStore = (position) => {
        const defaultDistance = 100000;
        let closestStore = {
            distance: defaultDistance,
            data: {},
        };

        options.data.forEach(data => {
            const userLat = position.coords.latitude;
            const userLng = position.coords.longitude;
            const storeLat = data.lat;
            const storeLng = data.lng;

            const x1 = storeLat - userLat;
            const distanceLat = toRad(x1);
            const x2 = storeLng - userLng;
            const distanceLng = toRad(x2);

            const a = Math.sin(distanceLat / 2) * Math.sin(distanceLat / 2) +
                Math.cos(toRad(userLat)) * Math.cos(toRad(storeLat)) *
                Math.sin(distanceLng / 2) * Math.sin(distanceLng / 2);

            const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
            const finalDistance = earthRadius * c;
            
            if (closestStore.distance > finalDistance) {
                closestStore = {
                    distance: finalDistance,
                    data: data
                };
            }
        });
        
        // If we have found a store nearby, show it.
        if (closestStore.distance !== defaultDistance) {
            __showNearestStore(closestStore.data);
        }
    };

    /**
     * Successfully located user.
     * 
     * @param {object} position 
     * 
     * @returns {void}
     */
    const __success = (position) => {
        __findNearestStore(position);
    };

    /**
     * Failed to locate user.
     * 
     * @param {object} position 
     * 
     * @returns {void}
     */
    const __error = (error) => {
        console.error(error);
    };

    /**
     * Retrieve users current location.
     * 
     * @returns {void}
     */
    const __getUserLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(__success, __error, geolocationConfig);
        } else {
            alert('Browser does not support geolocation.')
        }
    };

    /**
    * Publicly exposed init function.
    * 
    * @returns {void}
    */
    const init = () => {
        const requiredItems = [town, address, open, phone, link, description, options.data];

        if (exists(requiredItems)) {
            __getUserLocation();
        } else {
            console.warn('Cannot find all needed components to initialise CreateFindNearestStore');
        }
    };

    /**
     * Return publicly exposed init function.
     */
    return Object.freeze({
        init,
    });
};

export default CreateFindNearestStore;