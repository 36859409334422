// Import components
import CreateNavFlyout from './CreateNavFlyout';
import CreateDropdownMenu from './CreateDropdownMenu';

// accessible nav dropdown menu

const navDropdown = document.querySelectorAll(".header-nav-desktop__link"),
    helper = window.squiz.fn;

// navigate menu with keyboard
navDropdown.forEach(nav => {
    nav.addEventListener("keyup", function (e) {
        const toggles = ["Enter", "Spacebar", " "];

        // open menus from the keyboard
        if (toggles.indexOf(e.key) !== -1) {
            this.click();
            e.preventDefault();
        }

        // close menus from the keyboard or if the selected node does not have a parent with this dataset
        if (e.key === "Escape" || !helper.getParentDataset(this, "data-menu-expanded")) {
            closeAllMenus(navDropdown);
        }
    });
});

// mouse based events
document.addEventListener("click", function (e) {
    const target = e.target,
        classList = target.classList,
        parent = target.parentNode;

    // open / close menu with the toggle
    if (classList.contains("header-nav-desktop__link") && parent.dataset.menuExpanded) {
        if (parent.dataset.menuExpanded === "false") {
            target.setAttribute("aria-expanded", "true");
            parent.dataset.menuExpanded = "true";

            return;
        }

        target.setAttribute("aria-expanded", "false");
        parent.dataset.menuExpanded = "false";

        return;
    }

    // close menu without using the toggle
    closeAllMenus(navDropdown);
});

function closeAllMenus (menus) {
    menus.forEach(nav => {
        if (nav.parentNode.dataset && nav.parentNode.dataset.menuExpanded === "true") {
            nav.parentNode.dataset.menuExpanded = "false";
            nav.setAttribute("aria-expanded", "false");
        }
    });
}

// end accessible nav dropdown menu

// Setup the flyout nav selectors
const NavFlyout = new CreateNavFlyout({
    selectors: {
        flyoutElement: '.header-flyout-nav',
        toggleClose: '.header-flyout-nav .header-flyout__close',
        toggleOpen: '.header-nav-button--toggle-nav',
        searchButton: '.header-nav-button--toggle-search',
    },
    classes: {
        active: 'active',
        transition: 'transition',
        currentActive: 'header--nav'
    },
    isMobileSearch: false,
});

// Setup the flyout search selectors
const SearchFlyout = new CreateNavFlyout({
    selectors: {
        flyoutElement: '.header-flyout-search',
        toggleClose: '.header-nav-button--toggle-search',
        toggleOpen: '.header-nav-button--toggle-search',
    },
    classes: {
        active: 'active',
        transition: 'transition',
        currentActive: 'header--search'
    },
    isMobileSearch: true,
});

// Setup the selectors for the nav
const NewDropwdownMenu = new CreateDropdownMenu({
    selectors: {
        itemsWithDropdown: '.header-nav-desktop__item--has-children',
        topLevelLinks: '.header-nav-desktop > .header-nav-desktop__list > .header-nav-desktop__item',
        link: '.header-nav-desktop__link',
        previousLogo: '.header-nav-logos__serv-tas'
    },
    classes: {
        dropdown: 'header-nav-desktop__dropdown',
        active: 'active',
        itemsWithDropdown: 'header-nav-desktop__item--has-children',
    }
});

// Init components on page load
window.addEventListener('load', () => {
    NavFlyout.init();
    SearchFlyout.init();
    NewDropwdownMenu.init();
});
