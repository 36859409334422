/**
* Takes in an array of required items and confirms whether everything exists.
* 
* @param {array} items 
* 
* @returns {boolean}
*/
export const exists = (items) => {
    let valid = true;

    items.forEach(item => {
        if (item === undefined || item === null || item.length === 0) {
            valid = false;
        }
    });

    return valid;
};

/**
 * Convert a given number to radians.
 * 
 * @param {integer} x 
 * 
 * @returns {integer}
 */
export const toRad = x => {
    return x * Math.PI / 180;
};