import CreateSearchClear from './CreateSearchClear';

// Add clear button functionality to search page search bar.
const SearchPage = new CreateSearchClear({
   selectors: {
      field: '.search-header__search input[name="query"]',
      clear: '.search-header__search .icon-clear',
      pre: '.search-header__search pre[aria-hidden="true"]'
   },
   classes: {
      disabled: 'disabled'
   }
});

// Add clear button functionality to desktop navigation.
const NavigationDesktopHeader = new CreateSearchClear({
   selectors: {
      field: '.header-search input[name="query"]',
      clear: '.header-search .icon-clear',
      pre: '.header-search pre[aria-hidden="true"]'
   },
   classes: {
      disabled: 'disabled'
   }
});

// Add clear button functionality to desktop navigation.
const NavigationMobileHeader = new CreateSearchClear({
   selectors: {
      field: '.header-search--flyout input[name="query"]',
      clear: '.header-search--flyout .icon-clear',
      pre: '.header-search--flyout pre[aria-hidden="true"]'
   },
   classes: {
      disabled: 'disabled'
   }
});

// On page load, create the clear button functionality
window.addEventListener('load', () => {
   SearchPage.init();
   NavigationDesktopHeader.init();
   NavigationMobileHeader.init();
});
