import CreateAlert from './CreateAlert';

const Alert = new CreateAlert({
    selectors: {
        alert: '.alert',
        content: '.alert__content',
        toggle: '.alert__toggle',
    },
    classes: {
        active: 'active'
    }
});

window.addEventListener('load', () => {
    Alert.init()
});