import { exists } from '../../_global/js/utils';

/**
 * Enable native mobile app sharing for mobile devices. Disable in desktop,
 * only allow sharing via email.
 * 
 * Note: Web Share API only works for Android. iOS defaults to print.
 * 
 * @param {object} options 
 * 
 * @returns {object}
 */
const CreateShareThis = (options) => {
    const shareLink = document.querySelector(options.selectors.shareLink);
    const title = document.querySelector(options.selectors.title);
    const text = document.querySelector(options.selectors.text);
    const url = window.location.href;

    let shareProps = {};

    /**
     * Identify if current device is desktop.
     * 
     * @returns {boolean}
     */
    const __isDesktop = () => {
        const ua = navigator.userAgent;
        let isDesktop = true;

        if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua) ||
            /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)
        ) {
            isDesktop = false;
        }

        return isDesktop;
    };

    /**
     * Bind event listener to shareLink.
     * 
     * Note: navigator.canShare can only be accessed over HTTPS:
     * https://developer.mozilla.org/en-US/docs/Web/API/Navigator/canShare
     * 
     * Local testing is currently not possible, will have to deploy
     * work in order to test it over HTTPS. Therefore, using iOS simulator
     * on Mac will always default to sharing via email (fallback).
     * 
     * @returns {void}
     */
    const __bind = () => {
        shareProps = {
            url: url,
            title:  title.innerText ? title.innerText : '',
            text: text.innerText ? text.innerText : ''
        };

        // If is desktop or can't share, send via email functionality.
        if (__isDesktop() && !navigator.canShare) {
            shareLink.href = `mailto:?subject=${shareProps.title}&body=${shareProps.url}`;
        } else if (navigator.canShare(shareProps)) {
            shareLink.addEventListener('click', async e => {
                e.preventDefault();

                try {
                    await navigator.share(shareProps)
                } catch (error) {
                    console.warn(error);
                }
            });
        }
    };

    /**
     * Publicly exposed init function.
     * 
     * @returns {void}
     */
    const init = () => {
        const requiredItems = [shareLink, title, text, url];

        if (exists(requiredItems)) {
            __bind();
        } else {
            console.warn(`Cannot find share link with class: ${options.selectors.shareLink}. Please ensure this exists prior to initialising.`);
        }
    };

    /**
     * Return publicly exposed init function.
     */
    return Object.freeze({
        init
    });
};

export default CreateShareThis;