import { exists } from '../../_global/js/utils';

/**
 * Create the search header for the search page, namely, the clear button.
 * 
 * @param {object} options 
 * 
 * @returns {object}
 */
const CreateSearchClear = (options) => {
    const field = document.querySelector(options.selectors.field);
    const clearButton = document.querySelector(options.selectors.clear);
    
    // Pre is optional
    const pre = document.querySelector(options.selectors.pre);

    /**
     * Clear the search input.
     * 
     * @returns {void}
     */
    const __clearSearchInput = () => {
        field.value = '';

        if (exists([pre])) {
            pre.innerHTML = '';
        }
    };
    
    /**
     * 
     * 
     * @param {string} action 
     * 
     * @returns {void}
     */
    const __toggleClearButton = (action) => {
        if (action === 'show') {
            clearButton.classList.remove(options.classes.disabled);
        } else if (action === 'hide') {
            clearButton.classList.add(options.classes.disabled);
        }
    }
    
    /**
     * If the search is not populated, remove toggle.
     * 
     * @returns {void}
     */
    const __checkIfInputPopulated = () => {
        if (field.value.length >= 1) {
            __toggleClearButton('show');
        } else if (field.value.length === 0) {
            __toggleClearButton('hide');
        }
    };

    /**
     * The init function used to setup the event listener and init
     * our search clear.
     */
    const init = () => {
        const requiredItems = [field, clearButton];

        if (exists(requiredItems)) {
            __checkIfInputPopulated();

            clearButton.addEventListener('click', () => {
                __clearSearchInput();
                __checkIfInputPopulated();
            });

            field.addEventListener('keyup', () => {
                __checkIfInputPopulated();
            });
        } else {
            console.warn(`Cannot find all needed components to initialise search bar for: ${options.selectors.field}`);
        }
    }

    /**
     * Return the publicly exposed init function.
     */
    return Object.freeze({
        init
    });
};

export default CreateSearchClear;