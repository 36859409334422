window.onload = (event) => {
    if (document.querySelector('input[name="q291481:q1"]')) {
        document.querySelectorAll('input[name="q291481:q1"]').forEach((elem) => {
            elem.addEventListener("change", function(event) {
            let val = event.target.value;

            document.querySelector(".feedback-form__hidden").style.display = "block";

            if (val == "0") {
                // If yes, then hide the "improve" content
                document.querySelector(".feedback-form__improve").style.display = "none";
                document.querySelector(".feedback-form__like").style.display = "block";
            }

            if (val == "1") {
                // If no, then hide the "like" content
                document.querySelector(".feedback-form__like").style.display = "none";
                document.querySelector(".feedback-form__improve").style.display = "block";
            }
            });
        });
    }
}
