import { exists } from '../../_global/js/utils';

/**
 * Create tue services cards dropdown functionality.
 * 
 * @param {object} options 
 * 
 * @returns {object}
 */
const CreateServicesCards = (options) => {
    const DESKTOP_WIDTH = 992;
    const cards = Array.from(document.querySelectorAll(options.selectors.card));
    const positions = ['first', 'second', 'third'];
    const keyboardActions = {
        close: [
            'Escape',
            'ArrowUp'
        ],
        open: [
            'ArrowDown',
            'Enter'
        ]
    };

    /**
     * Toggle the tabindex values for the links in a given dropdown.
     * 
     * @param {object} dropdown 
     * @param {integer} tabindex 
     * 
     * @returns {void}
     */
    const __toggleLinkTabIndex = (dropdown, tabindex) => {
        const links = Array.from(dropdown.querySelectorAll(options.selectors.dropdownLinks));

        if (links.length > 0) {
            links.forEach(link => {
                link.tabIndex = tabindex;
            });
        }
    };

    /**
     * Grab the offsetHeight of the dropdown and set it in the DOM.
     * CSS transition will animate the height change for us.
     * 
     * @param {object} dropdown 
     * 
     * @returns {void}
     */
    const __setDropdownHeight = (dropdown, height) => {
        if (height !== 0) {
            const list = dropdown.querySelector(options.selectors.list);
            dropdown.style.height = `${list.offsetHeight}px`;
        } else {
            dropdown.removeAttribute('style');
        }
    }

    /**
     * Toggle the aria-attributes as well as the tabindex values for the
     * individual links. Toggle a new height for the hidden dropdown.
     * 
     * @param {object}  card 
     * @param {string}  action 
     * 
     * @returns {void}
     */
    const __toggleAttributes = (card, action) => {
        const dropdown = card.nextElementSibling,
            buttonTitle = card.querySelector("[data-card-title]");

        if (dropdown instanceof HTMLElement && action === 'close') {
            dropdown.setAttribute('aria-hidden', true);
            buttonTitle.textContent = "Show More";

            __toggleLinkTabIndex(dropdown, -1);
            __setDropdownHeight(dropdown, 0);
        } else if (dropdown instanceof HTMLElement && action === 'open') {
            dropdown.setAttribute('aria-hidden', false);
            buttonTitle.textContent = "Show Less";

            __toggleLinkTabIndex(dropdown, 0);
            __setDropdownHeight(dropdown);
        }
    };

    /**
     * Loop through all cards and close them.
     * 
     * @returns {void}
     */
    const __closeAllCards = () => {
        cards.forEach(card => {
            card.classList.remove(options.classes.active);
            __toggleAttributes(card, 'close');
        });
    };

    /**
     * Manually set the height of siblings of an opening card or
     * remove style attribute from every card.
     * 
     * @param {integer} card 
     * 
     * @returns {void}
     */
    const __setCardHeights = card => {
        // If we have a card, find it's sibling elements via the .row div.
        // Give each of the siblings a fixed height matching the active card.
        if (card instanceof HTMLElement) {
            const height = card.clientHeight;
            const grandparent = card.parentNode.parentNode;
            const siblings = grandparent instanceof HTMLElement ? Array.from(grandparent.querySelectorAll(options.selectors.card)) : false;

            if (siblings && siblings.length > 0) {
                siblings.forEach(sibling => {
                    sibling.style.height = `${height}px`;
                });
            }
        } else {
            // This section only runs when we resize the browser.
            cards.forEach(el => {
                el.removeAttribute('style');
            });
        }
    };

    /**
     * Toggle the card dropdown.
     * 
     * @param {object} card 
     * 
     * @returns {void}
     */
    const __toggleDropdown = card => {
        const windowWidth = window.innerWidth;
        const yOffset = card.offsetHeight;
        const yPos = card.getBoundingClientRect().top + window.pageYOffset - yOffset;
        const button = document.querySelector(options.selectors.button);

        // If we have an active class present, close the dropdown.
        // If not, we open the dropdown instead.
        if (card.classList.contains(options.classes.active)) {
            card.classList.remove(options.classes.active);

            __toggleAttributes(card, 'close');

            button.setAttribute("aria-expanded","false");
            button.setAttribute("aria-label", "Show More");
            
            // We need to wait for the dropdown height animation
            // to run before we remove the card height.
            setTimeout(() => {
                __setCardHeights();
            }, 175);
        } else {
            __closeAllCards();

            button.setAttribute("aria-expanded","true");
            button.setAttribute("aria-label", "Show Less");

            if (windowWidth > DESKTOP_WIDTH) {
                __setCardHeights(card);
            }

            card.classList.add(options.classes.active);
            __toggleAttributes(card, 'open');
        }
        
        window.scrollTo({top: yPos, behavior: 'smooth'});
    };

    /**
     * Attach event listeners to the components we need.
     */
    const __attachListeners = () => {
        cards.forEach(card => {
            card.addEventListener('click', e => {
                if (!e.target.classList.contains(options.classes.heading)) {
                    __toggleDropdown(card);
                }
            });
        });
    };

    /**
     * Loop through the cards and give them positions, pulled from the
     * positions array.
     * 
     * @returns {void}
     */
    const __setPositionClasses = () => {
        cards.forEach((card, i) => {
            card.classList.add(positions[i % 3]);
        });
    };

    /**
     * Init the component.
     * 
     * @returns {void}
     */
    const init = () => {
        const requiredItems = [
            cards
        ];

        // Ensure we have everything we need
        if (exists(requiredItems)) {
            __setPositionClasses();
            __attachListeners();
        }

        // On resizing the window, we need to close the dropdowns
        // as the calculations for height will be thrown off.
        window.addEventListener('resize', () => {
            __setCardHeights();
            __closeAllCards();
        });

        // Watch keyboard for any actions.
        window.addEventListener('keyup', e => {
            if (keyboardActions.close.includes(e.key)) {
                __setCardHeights();
                __closeAllCards();
            }
        });
    }

    /**
     * Expose the init function.
     */
    return Object.freeze({
        init
    });
};

export default CreateServicesCards;
