import { exists } from '../../_global/js/utils';

/**
 * Create the services accordion dropdowns.
 * 
 * @param {object} options 
 * 
 * @returns {object}
 */
const CreateServicesAccordions = (options) => {
    const accordions = Array.from(document.querySelectorAll(options.selectors.accordion));
    const keyboardActions = {
        close: [
            'Escape',
            'ArrowUp'
        ],
        open: [
            'ArrowDown',
            'Enter'
        ]
    };
    const positions = [
        'first',
        'second'
    ];

    /**
     * Toggle height of the accordion.
     * 
     * @param {object} acc 
     * @param {string} action 
     * 
     * @returns {void}
     */
    const __toggleHeight = (acc, action) => {
        const innerContent = options.isGovt ?
            acc.querySelector(options.selectors.govtInnerContent) :
            acc.querySelector(options.selectors.list)
        ;
        const dropdown = acc.querySelector(options.selectors.dropdown);
        let height = 0;

        if (exists([dropdown, innerContent])) {
            height = `${innerContent.offsetHeight + 2}px`;

            if (action === 'open') {
                dropdown.style.height = height;
                
                setTimeout(() => {
                    dropdown.style.height = 'auto';
                }, 200);
            } else if (action === 'close') {
                dropdown.style.height = height;

                setTimeout(() => {
                    dropdown.style.height = 0;
                }, 50);
            } else {
                console.error('Invalid action passed to CreateServicesAccordions component.');
            }
        }
    };
    
    /**
     * Close the currently active accordion.
     * 
     * @returns {void}
     */
    const __closeActiveAccordion = () => {
        accordions.forEach(acc => {
            if (acc.classList.contains(options.classes.active)) {
                __toggleAccordion(acc, acc.classList.contains(options.classes.active));
            }
        })
    };
    
    /**
     * Set the tabindex for the individual links in a given accordion.
     * 
     * @param {object} acc 
     * @param {string} action 
     * 
     * @returns {void}
     */
    const __setLinkTabIndexes = (acc, action) => {
        const links = Array.from(acc.querySelectorAll(options.selectors.link));
        
        if (exists([links])) {
            const tabindex = action === 'open' ? 0 : -1;
            links.forEach(link => {
                link.tabIndex = tabindex;
            });
        }
    };
    
    /**
     * Toggle the HTML attributes for screen readers.
     * 
     * @param {object} acc 
     * @param {string} action 
     * 
     * @returns {void}
     */
    const __toggleHTMLAttributes = (acc, action) => {
        const button = acc.querySelector(options.selectors.button).querySelector("button");
        const dropdown = acc.querySelector(options.selectors.dropdown);

        if (exists([button, dropdown])) {            
            switch (action) {
                case 'open':
                    button.setAttribute('aria-expanded', true);
                    dropdown.setAttribute('aria-hidden', false);
                    dropdown.removeAttribute('tabindex');
                    __setLinkTabIndexes(acc, action);
                    
                    break;
                    
                    case 'close':
                        button.setAttribute('aria-expanded', false);
                        dropdown.setAttribute('aria-hidden', true);
                        dropdown.setAttribute('tabindex', -1);
                        __setLinkTabIndexes(acc, action);
                
                    break;
            }
        }
    };
    
    /**
     * Toggle the services accordion active class and call other relevant
     * functions.
     * 
     * @param {object} acc 
     * 
     * @returns {void}
     */
    const __toggleAccordion = (acc) => {
        const action = acc.classList.contains(options.classes.active) ? 'close' : 'open';

        // Close the active accordions if they have the active class applied and close
        // the other active accordion. Else, open the accordion.
        if (action === 'close') {
            acc.classList.remove(options.classes.active);

            __toggleHeight(acc, action);
            __toggleHTMLAttributes(acc, action);
        } else if (action === 'open') {
            __closeActiveAccordion();
            acc.classList.add(options.classes.active);
            
            __toggleHeight(acc, action);
            __toggleHTMLAttributes(acc, action);
        }
    };

    /**
     * Bind event listeners to the accordion headings.
     * 
     * @returns {void}
     */
    const __bindEventListeners = () => {
        accordions.forEach(acc => {
            const button = acc.querySelector(options.selectors.button);

            if (button instanceof HTMLElement) {
                button.addEventListener('click', () => { __toggleAccordion(acc); });
                //button.addEventListener('keyup', e => {
                    //if (keyboardActions.close.includes(e.key)) {
                        //__closeActiveAccordion();
                    //} else if (keyboardActions.open.includes(e.key)) {
                        //__toggleAccordion(acc);
                    //}
                //});
            }
        });
        
        // Watch keyboard for any actions.
        window.addEventListener('keyup', e => {
            if (keyboardActions.close.includes(e.key)) {
                __closeActiveAccordion();
            }
        });
    };
    
    /**
     * Get the position for each of the accordions and set them via class.
     * 
     * @returns {void}
     */
    const __bindPositionClass = () => {
        accordions.forEach((acc, i) => {
            const dropdown = acc.querySelector(options.selectors.dropdown);

            if (exists([dropdown])) {
                dropdown.classList.add(positions[i % 2]);
            }
        });
    };

    /**
     * Init the component.
     * 
     * @returns {void}
     */
    const init = () => {
        if (exists(accordions)) {
            __bindPositionClass();
            __bindEventListeners();
        } else {
            console.warn('Cannot find all needed components to initialise services accordions.');
        }

        const accordionDropdowns = document.querySelectorAll(options.selectors.dropdown);

        accordionDropdowns.forEach(drop => {
            drop.setAttribute("aria-hidden", "true");
        });
    }

    /**
     * Expose the init function.
     */
    return Object.freeze({
        init
    });
};

export default CreateServicesAccordions;

