import CreateServicesCards from './CreateServicesCards';

const ServicesCardsConfig = {
    selectors: {
        card: '.services-card',
        list: '.services-card-dropdown__list',
        dropdownLinks: '.services-card-dropdown__list-link',
        button : ".services-card__toggle-accordion"
    },
    classes: {
        active: 'active',
        heading: 'services-card__heading'
    }
};

const ServicesCards = new CreateServicesCards(ServicesCardsConfig);

window.addEventListener('load', () => {
    ServicesCards.init();
});
