import { exists } from "../../_global/js/utils";

/**
 * Create the Google Map for the component.
 * 
 * @param {object} options 
 * 
 * @returns {object}
 */
const CreateGoogleMap = (options) => {
    const mapEl = document.querySelector(options.selectors.map);
    let infoWindow = null;
    let map = null;

    /**
     * Add pins to the map.
     * 
     * @returns {void}
     */
    const __buildPins = () => {
        const lat = mapEl.getAttribute(options.selectors.mapCenterLat);
        const lng = mapEl.getAttribute(options.selectors.mapCenterLng);
        map = new google.maps.Map(mapEl, {
            zoom: 7,
            center: new google.maps.LatLng(lat, lng)
        });
        options.data.forEach(data => {
            let marker = new google.maps.Marker({
                position: new google.maps.LatLng(data.lat, data.lng),
                map: map
            });

            marker.setMap(map);
            // build infowindow click handler
            google.maps.event.addListener(marker, 'click', function(event) {
				__generateInfoWindow(data, marker);
			});
        });
    };

    /**
     * Add infowindow to the map.
     */
     const __generateInfoWindow = (data, marker) => {
        // compose the infowindow content
        var html = `<div class="infowindow-service-centers__item">
                        <div class="infowindow-service-centers__content">
                            <h3 class="infowindow-service-centers__town">${data.town}</h3>
                            <p class="infowindow-service-centers__address">${data.address}</p>
                            <p class="infowindow-service-centers__description">${data.description}</p>
                            <p class="infowindow-service-centers__summary">
                                <span class="d-block">Opening hours:</span>
                                <span class="d-block">${data.open}</span>
                                <span class="d-block">Phone: <a href="tel:${data.phone.replace(/\s+/g, '')}">${data.phone}</a></span>
                            </p>
                            <a href="${data.link}" class="infowindow-service-centers__link">View on Google Maps</a>
                        </div>
                    </div>`;
            // apply the content to the infowindow
            infoWindow.setContent(html);
            // set the infowindow position to the marker
            infoWindow.setPosition(new google.maps.LatLng(data.lat, data.lng));
            // open it!
            infoWindow.open(map, marker);
     };

    /**
     * Publicly exposed init function.
     * 
     * @returns {void}
     */
    const init = () => {
        const requiredItems = [mapEl];

        if (exists(requiredItems)) {
            infoWindow = new google.maps.InfoWindow();
            __buildPins();
        } else {
            console.warn('Cannot find div with id #google-map. Please ensure that it has been added to the page.');
        }
    };

    /**
     * Return publicly exposed init function.
     */
    return Object.freeze({
        init,
    });
};

export default CreateGoogleMap;