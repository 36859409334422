(function(){
    'use strict';

    const timeTakenTable = document.querySelectorAll("#callOut");

    timeTakenTable.forEach(table => {
        table.setAttribute("role", "presentation");
    });

    // Global variables
    var squiz = {
        'fn': {},
        'vars': {}
    };

    const proto = squiz.fn;

    // Functions
    squiz.fn.sampleFunction = function() {

    };

    /**
     * find parent node
     *
     * @param {HTMLElement} node
     * the node to get the ancestors for
     *
     * @param {string} dataset
     * the dataset attribute
     *
     * @return {bool}
    */
    proto.getParentDataset = function (node, dataset) {
        while (node) {
            if (node.dataset && node.getAttribute(dataset)) {
                return node;
            }

            node = node.parentNode;
        }

        return false;
    }


    // Make squiz variable available to the console for debugging
    window.squiz = squiz;
}());
