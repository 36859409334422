import CreateGoogleMap from './CreateGoogleMap';
import CreateFindNearestStore from './CreateFindNearestStore';

const GoogleMap = new CreateGoogleMap({
    selectors: {
        map: '#google-map',
        mapCenterLat: 'data-map-center-lat',
        mapCenterLng: 'data-map-center-lng'
    },
    data: window.stores
});

const FindNearestStore = new CreateFindNearestStore({
    selectors: {
        town: '.find-service-center-nearest__town',
        address: '.find-service-center-nearest__address',
        open: '.find-service-center-nearest__open',
        phone: '.find-service-center-nearest__phone',
        link: '.find-service-center-nearest__link',
        description: '.find-service-center-nearest__description',
        component: '.find-service-center-nearest'
    },
    classes: {
        active: 'active'
    },
    data: window.stores
});

window.addEventListener('load', () => {
    GoogleMap.init();
    FindNearestStore.init();
});