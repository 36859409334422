import { exists } from '../../_global/js/utils';

/**
 * Extracts text data from the KMS generated component and injects it
 * into a custom styled key-info-cta component.
 * 
 * @param {object} options 
 * 
 * @returns {object}
 */
const CreateKeyInfoCta = (options) => {
    const actionStep = Array.from(document.querySelectorAll(options.selectors.actionStep));
    let linkText = '';
    let link = '';
    let summary = '';
    let image = '';
    let imageAlt = '';

    /**
     * Build the component.
     */
    const __build = (data) => {
        const parent = data[0].parentNode;
        
        parent.innerHTML = `
            <div class="row d-md-flex position-relative">
                <div class="col-12 col-md-8">
                    <h4>${data[3]}</h4>
                </div>
                <div class="col-8 d-flex align-items-center justify-content-center d-md-block">
                    <img src="${data[4]}" alt="${imageAlt ? imageAlt : 'Logo'}">
                </div>
                <div class="col-4 d-flex align-items-center justify-content-center d-md-block">
                    <a href="${data[2]}">${data[1]}</a>
                </div>
            </div>
        `;
    };
    
    /**
     * Init the component.
     * 
     * @returns {void}
     */
    const init = () => {
        const requiredItems = [actionStep];

        if (exists(requiredItems)) {
            actionStep.forEach(el => {
                let requiredTextItems = [];

                // Get all the bits of text data we need from the hidden table.
                linkText = el.querySelectorAll(options.selectors.linkText.el)[options.selectors.linkText.pos].innerHTML;
                link = el.querySelector(options.selectors.link)['href'];
                summary = el.querySelectorAll(options.selectors.summary.el)[options.selectors.summary.pos].innerHTML;
                image = el.querySelector(options.selectors.img)['src'];
                imageAlt = el.querySelector(options.selectors.img)['alt'];
                
                // Store the elements in a requiredText array for checking.
                requiredTextItems = [el, linkText, link, summary, image];

                // Confirm whether all required items exist.
                if (exists(requiredTextItems)) {
                    __build(requiredTextItems);
                }
            });
        } else {
            console.warn('Could not find everything needed to create Key Info CTA component.');
        }
    };
    
    /**
     * Return the publicly exposed init function.
     */
    return Object.freeze({
        init,
    });
}

export default CreateKeyInfoCta;