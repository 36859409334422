import { exists } from '../../_global/js/utils';

/**
 * Create the flyout nav for mobile users.
 * 
 * @param {object} options
 * 
 * @returns Object
 */
const CreateNavFlyout = (options) => {
  // Get all elements
  const headerClass = 'header';
  const baseFlyout = '.header-flyout';
  const toggleClose = document.querySelector(options.selectors.toggleClose);
  const toggleOpen = document.querySelector(options.selectors.toggleOpen);
  const flyoutElement = document.querySelector(options.selectors.flyoutElement);
  const allFlyoutElements = Array.from(document.querySelectorAll(baseFlyout));
  const searchButton = document.querySelector('.header-nav-button--toggle-search');
  const header = document.querySelector(`.${headerClass}`);
  const mainWrapper = document.querySelector('.main');
  const footer = document.querySelector('.footer');
  const alert = document.querySelector('.alert');

  const mainMenuClose = document.getElementById("start-main-menu");
  /**
   * Ensure only one flyout is active at any one time.
   * 
   * @returns {void}
   */
  const __closeAllFlyoutElements = () => {
    if (allFlyoutElements.length > 0) {
      allFlyoutElements.forEach(el => {
        if (el instanceof HTMLElement) {
          el.classList.remove(options.classes.active);
        }
      });
    }

    // Changes the class on the mobile button search. Removes the green close
    // icon, which defaults the button to the search glass.
    if (searchButton instanceof HTMLElement && !options.isMobileSearch) {
      searchButton.classList.remove(options.classes.active);
    }
  };

  /**
   * Toggle the flyout pannel classes to enable the fly in/out of the
   * navigation.
   * 
   * @param {string} action
   * 
   * @return {void}
   */
  const __toggleFlyout = action => {
    __closeAllFlyoutElements(action);

    if (action === 'open') {
      flyoutElement.classList.add(options.classes.active);

      // Toggle the main and footer aria-hidden attributes.
      mainWrapper.setAttribute('aria-hidden', 'true');
      footer.setAttribute('aria-hidden', 'true');
      alert instanceof HTMLElement ? alert.setAttribute('aria-hidden', 'true') : null;
      
      // Set the active flyout as a header class.
      header.className = headerClass;
      header.classList.add(options.classes.currentActive);
      mainMenuClose.focus();
    } else if (action === 'close') {
      flyoutElement.classList.remove(options.classes.active);
      
      // Toggle the main and footer aria-hidden attributes.
      mainWrapper.setAttribute('aria-hidden', 'false');
      footer.setAttribute('aria-hidden', 'false');
      alert instanceof HTMLElement ? alert.setAttribute('aria-hidden', 'false') : null;
    } else {
      console.warn('Invalid flyout panel action invoked.');
    }
  };

  /**
   * Toggle the tab index based off of screen width.
   * 
   * @return {void}
   */
  const __toggleTabIndex = () => {
    if (window.innerWidth >= 1299) {
      flyoutElement.tabIndex = -1;
      __toggleFlyout('close');
    } else if (flyoutElement.classList.contains(options.classes.active)) {
      flyoutElement.tabIndex = 0;
    }
  };

  /**
   * Toggle the search glass button style using a class.
   * 
   * @param {string} action 
   * 
   * @returns {void}
   */
  const __toggleSearch = () => {
    const action = toggleOpen.classList.contains(options.classes.active) ? 'close' : 'open';

    // Toggle the active class based off of the current action.
    if (action === 'open') {
      toggleOpen.classList.add(options.classes.active);

    } else if (action === 'close') {
      toggleOpen.classList.remove(options.classes.active);
    }

    __toggleFlyout(action);
  };

  /**
   * listens for keyboard events on the document
  */
  const __keyboardEvents = ({ key }) => {
    // close the navigation menu if esc is hit
    if (key === "Escape") __toggleFlyout("close");
  };

  /**
   * The publicly exposed init function.
   * 
   * @return {void}
   */
  const init = () => {
    const requiredItems = [
      toggleClose,
      toggleOpen,
      flyoutElement,
      header,
      mainWrapper,
      footer
    ];

    // Make sure we have all the elements we need.
    if (exists(requiredItems)) {
      const cover = flyoutElement.querySelector('.header-flyout__cover');

      // If the current flyout is the mobile search, only attach one listener.
      // The open/close is the same button for mobile search. The mobile navigation
      // has two different buttons for open/close actions.
      if (options.isMobileSearch && cover instanceof HTMLElement) {
        toggleOpen.addEventListener('click', () => __toggleSearch());
        cover.addEventListener('click', () => __toggleSearch());
      } else if (!options.isMobileSearch && cover instanceof HTMLElement) {
        toggleOpen.addEventListener('click', () => __toggleFlyout('open'));
        toggleClose.addEventListener('click', () => __toggleFlyout('close'));
        cover.addEventListener('click', () => __toggleFlyout('close'));
        document.addEventListener("keyup", __keyboardEvents);
      }

      // Add the transition class after page load due to the
      // transition timing. If it's loaded in with the page, the panel
      // will slide off screen as soon as the page loads. We don't want that.
      flyoutElement.classList.add(options.classes.transition);
      window.addEventListener('resize', () => __toggleTabIndex());
    } else {
      console.warn(`Cannot find all needed components to initialise flyout component: ${options.selectors.flyoutElement}.`);
    }
  };

  /**
   * Return the publicly exposed init function.
   */
  return Object.freeze({
    init,
  });
};

export default CreateNavFlyout;
