import { exists } from '../../_global/js/utils';

/**
 * Create the alert.
 * 
 * @param {object} options 
 * 
 * @returns {object}
 */
const CreateAlert = (options) => {
    const alert = document.querySelector(options.selectors.alert);
    const content = document.querySelector(options.selectors.content);
    const toggle = document.querySelector(options.selectors.toggle);

    /**
     * Check if the user has already closed the alert banner in the current session.
     * 
     * @returns {boolean}
     */
    const __wasClosed = () => {
        if (sessionStorage.getItem("alertClosed")) {
            __toggleAlert('close');
        }
    };

    /**
     * Toggle the alert based off of the active class or the given action.
     * 
     * @param {string} action 
     * 
     * @returns {void}
     */
    const __toggleAlert = (action) => {
        sessionStorage.setItem("alertClosed", true);

        if (content.classList.contains(options.classes.active) || action === 'close') {
            content.classList.remove(options.classes.active);
            toggle.classList.remove(options.classes.active);
            content.tabIndex = -1;
            content.ariaHidden = true;
        } else if (!content.classList.contains(options.classes.active) || action === 'open') {
            content.classList.add(options.classes.active);
            toggle.classList.add(options.classes.active);
            content.removeAttribute('tabIndex');
            content.ariaHidden = false;
        } else {
            console.error('Invalid action used for alert component. Use only "open" or "close".');
        }
    };

    /**
     * Init the alert component.
     */
    const init = () => {
        const requiredItems = [
            alert,
            content,
            toggle,
        ];

        // Ensure we have all the necessary compoment to init the component.
        // Apply keyboard/mouse fuctionality via event listeners.
        if (exists(requiredItems)) {
            toggle.addEventListener('click', () => __toggleAlert());
            toggle.addEventListener('keyup', e => {
                if (e.key === 'ArrowUp') {
                    __toggleAlert('close');
                } else if (e.key === 'ArrowDown') {
                    __toggleAlert('open');
                }
            });
            
            // Listen for window keyups, ensure the alert is closed when the up arrow or
            // escape button is pressed.
            window.addEventListener('keyup', e => {
                if (e.key === 'Escape' || e.key === 'ArrowUp') {
                    __toggleAlert('close');
                }
            });
            
            if (__wasClosed()) {
                __toggleAlert('close');
            }
        } else {
            console.warn('Cannot find all needed components to initialise alert component.');
        }
    };

    /**
     * Expose the init function for us to interface with.
     */
    return Object.freeze({
        init
    });
};

export default CreateAlert;