import { exists } from '../../_global/js/utils';

/**
 * Rebuild the KMS accordion and make it accessible.
 * 
 * @param {object} options 
 * 
 * @returns {object}
 */
const CreateKMSAccordions = (options) => {
    const nestedAccordionButtons = Array.from(document.querySelectorAll(options.selectors.nestedAccordionButtons));
    const keyboard = ['Space'];
    let accordionButtons = null;

    /**
     * Toggle the accesibility attributes for the activated accordion.
     * 
     * @param {object} button 
     * @param {object} dropdown
     * @param {string} action 
     * 
     * @return {void}
     */
    const __toggleAria = (button, dropdown, action) => {
        switch (action) {
            case 'open':
                button.setAttribute('aria-expanded', true);
                dropdown.setAttribute('aria-hidden', false);
                break;

            case 'close':
                button.setAttribute('aria-expanded', false);
                dropdown.setAttribute('aria-hidden', true);
                break;

            default:
                console.error('Invalid accordion action. Should be either "open" or "close".');
                break;
        }
    };
    
    /**
     * Toggle the dropdown tabindex.
     * 
     * @returns {void}
     */
    const __toggleDropdownTabindex = (dropdown, action) => {
        switch (action) {
            case 'open':
                dropdown.removeAttribute('tabindex');
                break;

            case 'close':
                dropdown.setAttribute('tabindex', -1);
                break;

            default:
                console.error('Invalid accordion action. Should be either "open" or "close".');
                break;
        }
    };

    /**
     * Toggle the KMS accordion.
     * 
     * @param {object} button
     * 
     * @returns {void}
     */
    const __toggleKMSAccordion = button => {
        const dropdown = button.nextElementSibling;
        
        // If we have a maxHeight attribute set, we're expanding.
        // If not, we are expanding.
        if (dropdown.style.maxHeight) {
            dropdown.style.maxHeight = null;
            button.classList.remove(options.classes.active);
            
            __toggleAria(button, dropdown, 'close');
            __toggleDropdownTabindex(dropdown, 'close');
        } else {
            dropdown.style.maxHeight = `${dropdown.scrollHeight}px`;
            button.classList.add(options.classes.active);
            
            __toggleAria(button, dropdown, 'open');
            __toggleDropdownTabindex(dropdown, 'open');
        }
    };
    
    /**
     * KMS accordion buttons come nested in a p tag, we need to replace it.
     * We also set some necessary accordion accessibility attributes, ready
     * to be toggled.
     * 
     * @returns {void}
     */
    const __createAccordionButtons = () => {
        nestedAccordionButtons.forEach((button, index) => {
            button.parentNode.outerHTML = `
                <button class="${options.classes.accordionButton}"
                    aria-expanded="false"
                    aria-controls="kms-accordion-${index+1}"
                >
                    ${button.parentNode.innerText}
                </button>
            `;
        });
    };
    
    /**
     * Bind the click and keyboard events to the button.
     * 
     * @param {object} button 
     * 
     * @returns {void}
     */
    const __bindEvents = button => {
        button.addEventListener("click", () => { __toggleKMSAccordion(button); });
        button.addEventListener("keyup", e => {
            if (keyboard.includes(e.code)) {
                __toggleKMSAccordion(button);
            }
        });
    };
    
    /**
     * Set the dropdown id and class attributes.
     * 
     * @param {object}  button 
     * @param {integer} index 
     * 
     * @returns {void}
     */
    const __setDropdown = (button, index) => {
        const dropdown = button.nextElementSibling;
        dropdown.setAttribute('class', options.classes.accordionDropdown);
        dropdown.setAttribute('id', `kms-accordion-${index+1}`);
        dropdown.setAttribute('aria-hidden', true);
        dropdown.setAttribute('tabindex', -1);
    };

    /**
     * Publicly exposed init function.
     * 
     * @returns {void}
     */
    const init = () => {
        const requiredItems = [nestedAccordionButtons];
        
        if (exists(requiredItems)) {
            __createAccordionButtons();
            accordionButtons = Array.from(document.querySelectorAll(options.selectors.accordionButtons));
            
            // Final prep for accordions.
            accordionButtons.forEach((button, index) => {
                __setDropdown(button, index);
                __bindEvents(button);
            });
        } else {
            console.warn('Cannot find nested accordion header in p tag. Accordions will not initialise.');
        }
    };
    
    /**
     * Return a publicly expsed init function.
     */
    return Object.freeze({
        init,
    });
};

export default CreateKMSAccordions;