import CreateServicesAccordions from './CreateServicesAccordions';

const ServicesAccordionsConfig = {
    selectors: {
        accordion: '.services-accordion',
        button: '.services-accordion__heading',
        accessibleButton : '[data-accordion="landing-accordion"]',
        dropdown: '.services-accordion-dropdown',
        list: '.services-accordion-dropdown__list',
        link: 'a',
        govtInnerContent: '.services-accordion-dropdown__content'
    },
    classes: {
        active: 'active'
    },
    isGovt: document.querySelector('body.govt-tas') instanceof HTMLElement,
};
const ServicesAccordions = new CreateServicesAccordions(ServicesAccordionsConfig);

window.addEventListener('load', () => {
    ServicesAccordions.init();
});
